<template>
  <div>
    <el-card shadow="never" style="padding: 16px 24px">
      <el-button type="primary" @click="_addTeacherBtn">新增讲师</el-button>
    </el-card>
    <!-- 主要内容--开始 -->
    <el-card shadow="never" style="margin-top: 16px">
      <div class="lecture-content">
        <!-- 讲师团队左侧菜单---开始 -->
        <div class="lecture-left">
          <div class="lecture-left-title">
            <span>讲师团队</span>
            <span>
              <el-button type='text' :style="{ color: colorTheme }" @click="_addTeamMenuBtn" >新增</el-button>
            </span>
          </div>
          <div class="lecture-left-content">
            <!-- :default-active="String(lectureTeamList[0]?.id)" -->
            <el-menu
              class="menu-vertical"
              :active-text-color="colorTheme">
              <div
                v-for="(item, index) in lectureTeamList"
                :key="index"
                @click="teacherListByLeftMenus(item, index)"
                style="margin-bottom:4px;">
                <el-menu-item
                  :index="String(item.id)"
                  :style="[
                    { '--hover-color': hoverColor },
                    { '--hover-borderColor': hoverBorderColor }]">
                  <div>{{ item.lecturerTeam }}</div>
                  <div>
                    <span @click.stop="_editTeamMenuBtn(item)">
                      <i class="el-icon-edit"></i>
                    </span>
                    <span @click.stop="_deleteTeamMenuBtn(item)" style="margin-left:10px;">
                      <i class="el-icon-delete"></i>
                    </span>
                  </div>
                </el-menu-item>
              </div>
            </el-menu>
          </div>
        </div>
        <!-- 讲师团队左侧菜单---结束 -->
        <!-- 右侧讲师表格---开始 -->
        <div class="lecture-right">
          <!-- 表格区域开始 -->
          <div class="table-box">
            <el-table
              :header-cell-style="cellHeaderStyle"
              :cell-style="cellStyle"
              :data="teatableData"
              max-height="550">
              <el-table-column
                v-for="(item, index) in tableColumns"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :width="item.width"
              >
              <template slot-scope="scope">
              <span>
                {{ scope.row[item.prop]||'—' }}
              </span>
            </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    class="text-btn"
                    @click="_editTeacherBtn(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    class="text-btn"
                    @click="_deleteTeacherBtn(scope.row.id)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <!-- 表格区域结束 -->
          </div>
          <!-- 右侧讲师表格---结束 -->
          <!-- 分页区域开始 -->
          <div class="paging-box">
            <el-pagination
              @size-change="_handleSizeChange"
              @current-change="_handleCurrentChange"
              :current-page="searchForm.page"
              :page-sizes="[10, 20, 30, 40, 50]"
              :page-size="searchForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="searchForm.total"
            >
            </el-pagination>
          </div>
          <!-- 分页区域结束 -->
        </div>
      </div>
    </el-card>
    <!-- 主要内容--结束 -->
    <!-- 讲师团队---新增&编辑弹框--开始 -->
    <el-dialog
      v-if="createTeamMenuDialog"
      class="team-menu-dialog"
      title="讲师团队"
      :visible.sync="createTeamMenuDialog"
      :close-on-click-modal="false"
      width="400px">
      <el-form :model="teamMenuForm" ref="teamMenuForm" label-width="80px" :rules="teamRules">
        <el-form-item label="名称" prop="lecturerTeam">
          <el-input placeholder="请输入名称" v-model="teamMenuForm.lecturerTeam" clearable></el-input>
        </el-form-item>
        <el-form-item label="可见范围" prop="useType">
          <el-radio-group v-model="teamMenuForm.useType">
            <el-radio :label="2">可见范围</el-radio>
            <el-radio :label="1">全部</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" prop="useDeptId" v-if="teamMenuForm.useType==2">
          <el-select filterable value-key="id" clearable v-model="teamMenuForm.useDeptId" placeholder="请选择" style="width: 275px">
            <el-option
              v-for="item in visibleDeptList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              @click.native="_selectDept(item)"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="createTeamMenuDialog = false">取 消</el-button>
        <el-button type="primary" @click="_teamMenuDialogOk">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 讲师团队---新增&编辑弹框--结束 -->
    <!-- 讲师信息--编辑--开始 -->
    <el-dialog
      v-if="editTeacherDialog"
      class="teacher-dialog"
      title="讲师编辑"
      :visible.sync="editTeacherDialog"
      :close-on-click-modal="false"
      width="400px">
      <el-form :model="teacherForm" label-width="40px">
        <el-form-item label="姓名">
          <span>{{teacherForm.employeeName}}</span>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" autosize placeholder="请输入备注" v-model="teacherForm.remark" rows="1"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editTeacherDialog = false">取 消</el-button>
        <el-button type="primary" @click="_teacherDialogOk">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 讲师信息--编辑--结束 -->
      <!-- 新增讲师弹窗--开始 -->
      <teacherEdit
      ref="teacherEdit"
      :propTitle="propTitle"/>
      <!-- 新增讲师弹窗--结束 -->
  </div>
</template>
<script>
import {
  getTeachTeamListApi,
  getTeachTeamAddOrEditApi,
  getTeachTeamDeleteAPi,
  getTeachUserListApi,
  getTeachUserDeleteAPi,
  getTeachUserAddOrEditApi
} from '@/api/settings'
import teacherEdit from './components/teacherEdit'
import tableFirstCol from '@/mixins/tableFirstCol'
import { getManageDeptListApi } from '@/api/api'
export default {
  mixins: [tableFirstCol],
  components: {
    teacherEdit
  },
  data () {
    return {
      colorTheme: localStorage.getItem('tremePackers'),
      hoverColor: this._hexToRgb(localStorage.getItem('tremePackers')),
      hoverBorderColor: localStorage.getItem('tremePackers'),
      propTitle: '',
      tableColumns: [
        { label: '姓名', prop: 'employeeName' },
        { label: '部门', prop: 'deptName' },
        { label: '备注', prop: 'remark' }
      ],
      teatableData: [],
      lectureTeamList: [],
      createTeamMenuDialog: false,
      teamMenuForm: {
        lecturerTeam: '', // 讲师团队名称
        useDeptId: '', // 使用部门id
        useDeptName: '', // 使用部门name
        useType: '', // 可见范围
        id: ''
      },
      teacherForm: {
        lecturerTeamId: '', // 讲师团队ID
        employeeId: '', // 员工id
        employeeName: '', // 员工name
        deptId: '',
        deptName: '',
        remark: '', // 备注
        id: undefined
      },
      searchForm: {
        lecturerTeamId: '', // 讲师团队ID
        page: 1,
        pageSize: 10,
        total: 0
      },
      editTeacherDialog: false,
      currentTeam: null,
      selectDepartmentShow: false,
      visibleDeptList: [],
      teamRules: {
        lecturerTeam: [
          { required: true, message: '请输入名称', trigger: ['change', 'blur'] }
        ],
        useType: [
          { required: true, message: '请选择可见范围', trigger: ['change', 'blur'] }
        ],
        useDeptId: [
          { required: true, message: '请选择部门', trigger: ['change', 'blur'] }
        ]
      }
    }
  },
  created () {
    this.getTeachTeamList()// 左侧菜单
    this._getRightTeacherList()// 右侧讲师列表
    this._getManageDeptList()// 部门下拉
  },
  methods: {
    // 左侧讲师团队菜单--列表
    getTeachTeamList () {
      getTeachTeamListApi().then((res) => {
        if (res.code === 200) {
          this.lectureTeamList = res.data
        }
      })
    },
    //  新增讲师团队菜单
    _addTeamMenuBtn () {
      this.teamMenuForm = {
        lecturerTeam: '', // 讲师团队名称
        useDeptId: '', // 使用部门id
        useDeptName: '', // 使用部门name
        useType: '', // 可见范围
        id: undefined
      }
      this.createTeamMenuDialog = true
    },
    // 编辑讲师团队菜单
    _editTeamMenuBtn (item) {
      this.teamMenuForm.id = item.id
      this.teamMenuForm.lecturerTeam = item.lecturerTeam
      this.teamMenuForm.useDeptId = item.useDeptId
      this.teamMenuForm.useDeptName = item.useDeptName
      this.teamMenuForm.useType = item.useType
      this.createTeamMenuDialog = true
    },
    // 讲师讲师团队新增确认
    _teamMenuDialogOk () {
      this.$refs.teamMenuForm.validate((valid) => {
        if (valid) {
          getTeachTeamAddOrEditApi({
            ...this.teamMenuForm,
            id: this.teamMenuForm.id ? this.teamMenuForm.id : undefined
          }).then((res) => {
            if (res.code === 200) {
              this.createTeamMenuDialog = false
              this.getTeachTeamList()
              this.$message.success('操作成功！')
            }
          })
        }
      })
    },
    // 删除团队菜单
    _deleteTeamMenuBtn (item) {
      this.$confirm('确认删除该讲师团队吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getTeachTeamDeleteAPi([item.id]).then((res) => {
          if (res.code === 200) {
            this.getTeachTeamList()
            this.$message.success('删除成功！')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击左侧菜单查询右侧表格列表-- 点击讲师团队
    teacherListByLeftMenus (item, index) {
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      this.searchForm.lecturerTeamId = item.id
      this._getRightTeacherList()
    },
    // 右侧表格讲师--列表
    _getRightTeacherList () {
      getTeachUserListApi({ ...this.searchForm }).then((res) => {
        if (res.code === 200) {
          this.teatableData = res.object.results
          this.searchForm.total = res.object.total
        }
      })
    },
    // 添加讲师-- 新增
    _addTeacherBtn () {
      this.$refs.teacherEdit.show()
      this.propTitle = '添加讲师'
    },
    // 编辑讲师
    _editTeacherBtn (item) {
      this.teacherForm = JSON.parse(JSON.stringify(item))
      this.editTeacherDialog = true
    },
    // 编辑讲师提交
    _teacherDialogOk () {
      getTeachUserAddOrEditApi([this.teacherForm]).then((res) => {
        if (res.code === 200) {
          this.editTeacherDialog = false
          this._getRightTeacherList()
          this.$message.success('编辑成功！')
        }
      })
    },
    // 删除讲师
    _deleteTeacherBtn (id) {
      this.$confirm('确认删除该讲师团队吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getTeachUserDeleteAPi([id]).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this._getRightTeacherList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 分页
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this._getRightTeacherList()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this._getRightTeacherList()
    },
    // 部门下拉
    _getManageDeptList () {
      getManageDeptListApi().then((res) => {
        if (res.code === 200) {
          this.visibleDeptList = res.data
        }
      })
    },
    // 选择部门
    _selectDept (item) {
      this.teamMenuForm.useDeptId = item.id
      this.teamMenuForm.useDeptName = item.name
    },
    // 主题颜色--更改颜色色值
    _hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? 'rgba' +
            '(' +
            parseInt(result[1], 16) +
            ',' +
            parseInt(result[2], 16) +
            ',' +
            parseInt(result[3], 16) +
            ',' +
            0.2 +
            ')'
        : null
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-card__body {
  padding: 0px;
}
/deep/.el-table--small {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
/deep/.el-table thead {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  height: 54px;
  background-color: #ffffff !important;
}
/deep/.el-table tr {
  height: 51px!important;
}
.lecture-content {
  height: calc(100vh - 230px);
  padding: 16px 0px;
  display: flex;
  .lecture-left {
    width: 20%;
    // 左侧标题
    .lecture-left-title {
      padding: 0px 16px 16px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f3f3f3;
      span:nth-child(1) {
        color: rgba(0, 0, 0, 0.9);
        font-size: 16px;
        font-weight: 500;
      }
      span:nth-child(2) {
        font-size: 14px;
      }
    }
    // 左侧内容
    .lecture-left-content {
      padding: 6px 16px 0px 8px;
      .menu-vertical {
        /deep/.el-menu-item {
          padding-left: 8px !important;
          height: 26px;
          line-height: 26px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #303133;
          font-size: 14px;
          padding: 0px;
        }
        .el-menu-item.is-active {
          background: var(--hover-color) !important;
          font-size: 14px !important;
        }
        .el-menu-item:hover {
          background-color: var(--hover-color) !important;
          color: var(--hover-borderColor);
        }
      }
    }
  }
  .lecture-right {
    padding: 0px 8px;
    width: 80%;
    border-left: 1px solid #f3f3f3;
  }
}
.lecture-left-item {
  display: flex;
  justify-content: space-between;
}
/deep/.el-menu {
  border-right: none !important;
}
.el-icon-delete,
.el-icon-edit {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-bottom: 6px !important;
  margin-left: 4px;
}
.team-menu-dialog{
  /deep/.el-form-item__label{
    color: rgba(0, 0, 0, 0.9);
  }
}
</style>
